.home-container {
  background-color: transparent;
  height: 100%;
}

/* Place text in the middle of the image */
/* .hero-text {
  background-color: #ffffff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 20px 0 20px 0;
} */

/* Center Intro CSS */
.title-collection {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  width: 100vw;
}

.title {
  color: #000000;
}

.home-text {
  color: #000000 !important;
}

.tri-right {
  width: 0;
  height: 0;
  margin-left: 10px;
  border-style: solid;
  border-width: 10px 0 10px 30px;
  border-color: transparent transparent transparent #000000;
}

.tri-left {
  width: 0;
  height: 0;
  margin-right: 10px;
  border-style: solid;
  border-width: 10px 30px 10px 0;
  border-color: transparent #000000 transparent transparent;
}

.temp-span {
  width: 100%;
  color: #fff;
  text-align: center;
  top: 30%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  margin: 0;
}
