.page-title {
  margin: 30px;
}

.project-collection {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.project-button {
  -webkit-box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.75);
  padding: 10px;
  width: 250px;
  display: flex;
  justify-content: space-around;
}

.project-button:hover {
  background-color: #8a8a8a;
}
