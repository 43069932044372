.card-container {
  cursor: pointer;
  height: 400px;
  width: 400px;
  perspective: 800px;
  position: relative;
  margin: auto;
  transition: ease-in;
}

.card {
  height: 400px;
  width: 400px;
  position: absolute;
  border: 1px solid #300;
  transform-style: preserve-3d;
  transition: all 0.4s ease-in-out;
}

.card:hover {
  transform: rotateY(180deg);
  transition: all 0.4s ease-in-out;
}

.card .front {
  height: 400px;
  width: 400px;
  backface-visibility: hidden;
  position: absolute;
  overflow: hidden;
  text-align: center;
  background-color: #5d7eab;
}

.card .back {
  height: 400px;
  width: 400px;
  text-align: center;
  transform: rotateY(180deg);
  background-color: #5daba9;
}

.term {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
}

.definition {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-items: center;
}
