body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  /* max-width: 100vw; */
  max-height: 100vh;

  background-color: #23232b;
  /* background-image: url(./assets/images/upside-down.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; */

  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #cccccc;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #b30000;
  border-radius: 25px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #661a21;
}

h1 {
  margin: 0;
  color: #ffffff;
  text-align: center;
}

p {
  margin: 0;
  color: #ffffff;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span {
  color: #000000;
  font-size: 20px;
  margin: 2vw;
}

a {
  position: relative;
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  color: #000;
}

a:visited {
  color: #ffffff;
}

a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background: #000000; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #000000 0%,
    #ff0509 100%,
    #ce7889 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #000000 0%,
    #ff0509 100%,
    #ce7889 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #000000 0%,
    #ff0509 100%,
    #ce7889 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ce7889',GradientType=1 ); /* IE6-9 */
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.page-container {
  /* min-width: 100vw; */
  max-width: 2300px;
  min-height: 100%;
  margin: 0 auto;
}

.header-collection {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: transparent;
  /* background-image: url(./assets/images/sunset1.jpg);
  background-repeat: no-repeat;
  background-position-y: 22%;
  background-size: cover; */
}

.header-row {
  display: inline-flex;
  height: auto;
  margin: 15px 0 15px 0;
  overflow: hidden;
}

.page-links {
  overflow: hidden;
  margin-left: 50px;
}

@media (max-width: 500px) {
  .page-links {
    margin-left: 0px;
  }
  .header-row {
    display: flex;
    flex-wrap: wrap;
  }
}

/* Global Hero */
/* Place text in the middle of the image */
.hero-text {
  background-color: #ffffff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000000;
  padding: 20px 0 20px 0;
}

/* Global Hero Inverse */
.hero-inverse {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
}
