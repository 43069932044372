.formComponent {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 40px;
}

.cardInstruct {
  max-width: 100%;
  text-align: center;
  margin: 15px 0 15px 0;
}

.formEntry {
  display: inline-block;
}

.cardText {
  color: #ffffff;
  max-width: 80%;
  margin: 0 auto;
}

.cardList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  align-content: flex-end;
  width: 100%;
  margin: 0;
}

.gitCard {
  /* width calculated within media queries */
}

.innerGitCard {
  width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;
  margin-bottom: 50px;
}

.gitLink {
  margin: 0 auto;
}

.gitImg {
  width: 50%;
  height: auto;
}

@media (max-width: 2560px) {
  .gitCard {
    width: calc(100% / 4);
  }
}
@media (max-width: 1440px) {
  .gitCard {
    width: calc(100% / 4);
  }
}
@media (max-width: 1024px) {
  .gitCard {
    width: calc(100% / 3);
  }
}
@media (max-width: 768px) {
  .gitCard {
    width: calc(100% / 2);
  }
}
@media (max-width: 425px) {
  .gitCard {
    width: calc(100% / 1);
  }
}
@media (max-width: 375px) {
}
