.page-title {
  margin: 30px;
}

.about-title {
  margin-bottom: 50px;
}

.about-container {
  background-color: transparent;
  max-width: 100%;
}

.about-inner-container {
  max-width: 100%;
  height: 100%;
}

.about-top-container {
  padding: 20px;
}

.about-content {
  max-width: 1100px;
  /* margin: 3% 4% 4% 4%; */
  min-height: 325px;
  margin: 0 auto;
}

.about-image {
  width: 300px;
  height: 300px;
  background-image: url(../../assets/images/itsame.jpg);
  color: #111;
  border-radius: 50%;
  text-align: center;
  font-size: 90px;
  float: left;
  shape-outside: ellipse();
}

.about-divider {
  height: 2px;
  width: 90%;
  margin: 0 auto;
  background: #000000; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #000000 0%,
    #ff0509 100%,
    #ce7889 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #000000 0%,
    #ff0509 100%,
    #ce7889 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #000000 0%,
    #ff0509 100%,
    #ce7889 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ce7889',GradientType=1 ); /* IE6-9 */
}

.hobby-section {
  margin-top: 2vh;
  /* margin-bottom: 10vh; */
}

.hobby-container {
  max-width: 100%;
  padding: 3% 4% 4% 4%;
  min-height: 215px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.hobby-content {
  width: 500px;
  height: 350px;
  margin-bottom: 50px;
  border: 2px solid #ffffff;
}

.hobby-title {
  width: 100%;
  color: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.hobby-description {
  padding: 10px;
  height: 180px;
}

.hobby-image {
  background-color: red;
  position: relative;
  width: 98%;
  padding-top: 25%;
  margin-bottom: 10px;
  /* width: 100%;
    height: ;
    padding: 10px;
    background-image: url(../../assets/images/itsame.jpg);
    color: #111;
    border-radius: 50%;
    text-align: center;
    font-size: 90px;
    float: left;
    shape-outside: ellipse(); */
}

.hobby-text {
  width: 100%;
  margin: 0;
}

.hi1 {
  background-image: url(../../assets/images/mariopuns.png);
  background-position-x: 44%;
  background-position-y: 36%;
}

.hi2 {
  background-image: url(../../assets/images/2012sportster.jpg);
  background-position-x: 60%;
  background-position-y: 44%;
}

.hi3 {
  background-image: url(../../assets/images/armoire.jpg);
  background-position-x: 11%;
  background-position-y: 26%;
}

.hi4 {
  background-image: url(../../assets/images/bodega.jpg);
  background-position-x: 61%;
  background-position-y: 36%;
}

/* @media (min-width: 1440px) {
  .hobby-content {
    width: calc(100% / 4);
    margin: 0 auto;
    float: left;
  }
  .about-text {
    margin-top: 70px;
  }
}

 @media (max-width: 1440px) {
  .hobby-content {
    width: calc(100% / 2);
    float: left;
  }
  .about-text {
    margin-top: 70px;
  }
}

@media (max-width: 1024px) {
  .hobby-content {
    width: calc(100% / 2);
    float: left;
  }
  .hi1 {
    background-position-x: 41%;
    background-position-y: 37%;
  }
}

@media (max-width: 768px) {
  .hobby-content {
    width: calc(100% / 2);
    float: left;
  }
  .about-text {
    margin: 50px 0 50px 0;
  }
}

@media (max-width: 425px) {
  .hobby-content {
    min-width: 100%;
  }
}

@media (max-width: 397px) {
  .about-image {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 320px) {
  .about-image {
    width: 200px;
    height: 200px;
    background-position-x: 41%;
    background-position-y: 11%;
  }
}

 */
