.highLight {
  width: 80%;
  margin: 30px auto;
  background-color: #ffffff;
  border: 2px solid #2aaccc;
}

.boxLink {
  color: #2aaccc !important;
}
